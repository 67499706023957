





































import {
  Component, Vue,
} from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';

import RankingUsers from './components/RankingUsers/RankingUsers.vue';
import Levels from './components/Levels/Levels.vue';
import Instructions from './components/Instructions/Instructions.vue';

import GamificationService from '@/services/Gamification/gamificationService';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import { RankingData } from '@/globalInterfaces/Gamification';

@Component({
  components: {
    PageHeader,
    RankingUsers,
    Levels,
    Instructions,
    Footer,
  },
})
export default class Ranking extends Vue {
  private isLoadingUserRanking = false;

  private GamificationService = new GamificationService();

  private userRanking: RankingData | null = null;

  created() {
    this.getUserRanking();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  async getUserRanking() {
    try {
      this.isLoadingUserRanking = true;

      const userRanking = await this.GamificationService.getUserRanking();

      this.userRanking = userRanking;
    } catch (error) {
      if (error?.response?.status === STATUS_RESPONSE_API.CLIENT_ERROR_NOT_FOUND) return;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar seus dados no ranking',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingUserRanking = false;
    }
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Gamificação', to: null },
    ]);
  }
}
