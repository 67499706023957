















































































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import store from '@/store';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import AvatarRanking from '@/components/AvatarRanking/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Loading from '@/components/Loading/Loading.vue';

import RankingLoading from '@/components/Ranking/RankingLoading.vue';
import RankingUser from '../RankingUser/RankingUser.vue';

import GamificationService from '@/services/Gamification/gamificationService';

import { RankingData, User } from '@/globalInterfaces/Gamification';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

const ID_PLAN_PREMIUM_ANNUAL = '39';
const LIMIT_DEFAULT = 10;
const PAGE_DEFAULT = -1;

const MAX_USERS_RANKING = 100;

@Component({
  components: {
    BoxContainer,
    AvatarRanking,
    RankingLoading,
    RankingUser,
    FeedbackUser,
    Loading,
  },
  filters: {
    formatName(user: User) {
      if (!user) return '';

      const { idstudent } = store.getters.profile;

      const splitName = user.name.split(' ');

      if (idstudent && user.ID === idstudent) return `${splitName[0]} (você)`;

      return splitName[0];
    },
  },
})
export default class RankingUsers extends Vue {
  @Prop({ default: null }) userRanking!: RankingData | null;
  @Prop({ default: false }) loading!: boolean;

  private isLoadingRanking = true;
  private errorRanking = false;

  private totalPage = PAGE_DEFAULT;
  private page = PAGE_DEFAULT;
  private limit = LIMIT_DEFAULT;

  private ranking: RankingData[] = [];

  private GamificationService = new GamificationService();

  created() {
    this.getRanking();
  }

  get profile() {
    return this.$store.getters.profile;
  }

  get idStudent() {
    return this.profile?.idstudent;
  }

  get isLimitPage() {
    return (this.page * this.limit) >= this.totalPage;
  }

  get isMoreOneHundredUsers() {
    return this.ranking.length >= MAX_USERS_RANKING;
  }

  async getRanking() {
    try {
      if (this.isLimitPage || this.isMoreOneHundredUsers) return;

      this.isLoadingRanking = true;

      const { ranking, total } = await this.GamificationService.getRanking({
        page: this.page + 1,
        limit: this.limit,
      });

      if (ranking && ranking.length) {
        this.ranking = [...this.ranking, ...ranking];
        this.totalPage = Math.floor(total / this.limit);
        this.page += 1;
      }
    } catch (error) {
      this.errorRanking = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o ranking',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingRanking = false;
    }
  }

  handleRedirectPlan() {
    this.$router.push({
      name: 'Payment',
      params: {
        feature: 'planos',
        productId: ID_PLAN_PREMIUM_ANNUAL,
      },
    });
  }
}
