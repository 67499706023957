






































import {
  Component, Vue,
} from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';

import Home from '@/assets/icons/ranking/Home.vue';
import Question from '@/assets/icons/ranking/Question.vue';
import QuestionFull from '@/assets/icons/ranking/QuestionFull.vue';

import { LIST_INSTRUCTIONS } from '../../constants/ListInstructions';

@Component({
  components: {
    BoxContainer,
    Home,
    Question,
    QuestionFull,
  },
})
export default class Instructions extends Vue {
  private LIST_INSTRUCTIONS = LIST_INSTRUCTIONS;
}
