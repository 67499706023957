export const LIST_INSTRUCTIONS = [
  {
    icon: 'Home',
    exp: 1,
    message: 'Acesso diário',
  },
  {
    icon: 'Question',
    exp: 2,
    message: 'Fazer exercício dos Cadernos',
  },
  {
    icon: 'QuestionFull',
    exp: 2,
    message: 'Gabaritar exercício dos Cadernos',
  },
];
